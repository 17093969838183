import { Component, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, LoadingController, Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage-angular';
import { Apollo, gql } from 'apollo-angular';
import { filter, find, map, mergeMap, of, switchMap } from 'rxjs';
import { GraphqlService } from './query.service';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { RequiredRole } from './directives/required-role';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
  public selectedIndex = null;
  user = null;
  public appPages = [];
  pageData: any;
  selectedItem: string = '';



  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private auth: AuthService,
    private apolloService: Apollo,
    private storage: Storage,
    private router: Router,
    private keycloakService: KeycloakService,
    private translateService: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    await this.storage.create();
    this.createMenu();
    const path = window.location.pathname.split('/')[1];
    this.router.events.pipe(filter((ev: any) => ev?.url)).subscribe(event => {
      this.activateMenuItem(event.url.split("/")[1]);
    });
  }

  createMenu() {
    this.appPages = [
      {
        title: "MENU.ADMIN",
        icon: 'settings',
        value: 'admin',
        url: '',
        children: [
          {
            title: "MENU.ADMINGROUP.ORGANIZATIONS",
            url: 'organizations',
            icon: 'business',
            value: 'organizations',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.organizations)
          },
          {
            title: "MENU.ADMINGROUP.USERS",
            url: 'users',
            icon: 'people',
            value: 'users',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.users)
          },
          {
            title: "MENU.ADMINGROUP.SENSORNODE",
            url: 'sensor_nodes',
            icon: 'pulse',
            value: 'sensor_nodes',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.sensorNodes)
          },
          {
            title: "MENU.ADMINGROUP.SENSORS",
            url: 'sensors',
            icon: 'radio',
            value: 'sensors',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.sensors)
          }, {
            title: "MENU.ADMINGROUP.DIAGNOSTICSENSORS",
            url: 'diagnostic_sensors',
            icon: 'cellular',
            value: 'diagnostic_sensors',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.diagnosticSensors)
          }
        ]
      },
      {
        title: "MENU.OPERATIONS",
        url: 'operations',
        icon: 'build',
        value: 'operation',
        children: [
          {
            title: 'MENU.OPERATIONSGROUP.PRESET',
            url: 'sensor_nodes_preset',
            icon: 'construct',
            value: 'preset',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.preset)
          }, {
            title: 'MENU.OPERATIONSGROUP.DIAGNOSTIC',
            url: 'diagnostic',
            icon: 'build',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.diagnostic)
          },
          {
            title: 'MENU.OPERATIONSGROUP.INVALIDDATA',
            url: 'invalid-data',
            icon: 'warning',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.invalidData)
          }
        ]
      },
      {
        title: 'MENU.KEYCLOAK',
        url: 'keycloak-info',
        icon: 'cog',
        children: [
          //TODO da utilizzare in futuro per effettuare tramite ui le operazioni comuni che si effettuano su keycloak
          /*{
            title: "MENU.KEYCLOAKGROUP.AUDIT",
            url: 'organizations',
            icon: 'cog',
            value: 'organizations',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.keycloak)
          },*/
          {
            title: "MENU.KEYCLOAKGROUP.LOGGEDUSER",
            url: 'keycloak-info',
            icon: 'cog',
            value: 'keycloak-info',
            children: [],
            requiredRoles: this.checkRole(environment.keycloak.configAdminApp.keycloak)
          },
        ],
      }
    ];
  }

  checkRole(role: string) {
    return RequiredRole.hasRequiredRole(this.keycloakService, {
      client: environment.keycloak.configAdminApp.clientId,
      role: role
    })
  }

  activateMenuItem(path) {
    if (path !== undefined) {
      if (path === 'home') {
        this.selectedItem = 'home';
        this.accordionGroup.value = "";
        this.storage.set("selectedMenuItem", { firstLevel: this.selectedItem, secondLevel: '' });
      } else {
        const itemFather = this.appPages.find(page => page.children.find(ch => ch.url.toLowerCase() === path.toLowerCase()));
        if (itemFather) {
          this.selectedItem = itemFather.children.find(ch => ch.url.toLowerCase() === path.toLowerCase()).url;
          this.accordionGroup.value = itemFather.value;
          this.storage.set("selectedMenuItem", {
            firstLevel: {
              ...itemFather,
              children: []
            },
            secondLevel: itemFather.children.find(ch => ch.url.toLowerCase() === path.toLowerCase())
          });
        }
      }
    };
  }

  showAccordion(page: any[]) {
    return page.some((element) => element.requiredRoles);
  }

  ionViewWillEnter() {
    this.user = this.auth.getUser();
  }

  logout() {
    this.keycloakService.logout();
    localStorage.removeItem("access_token");
    this.selectedIndex = '0';
  }
}

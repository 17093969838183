import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import gql from 'graphql-tag';
import { Mutation, Apollo } from 'apollo-angular';

const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<any>;
  private userData = new BehaviorSubject(null);

  constructor(private storage: Storage, private http: HttpClient, private plt: Platform, private router: Router, private apollo: Apollo) {
    this.loadStoredToken();
  }

  loadStoredToken() {
    let platformObs = from(this.plt.ready());
    this.user = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(TOKEN_KEY));
      }),
      map(token => {
        if (token) {
          let decoded = helper.decodeToken(token);
          this.userData.next(decoded);
          return true;
        } else {
          return null;
        }
      })
    );
  }

  login(email: string, pw: string) {
    let document = gql`
    mutation login($username:String!, $password:String!) {
      login( input: {
      username:$username
      password:$password
      } )
    }`;
    return this.apollo.mutate(
      {
        mutation: document,
        variables: { username: email, password: pw }
      }).pipe(
        take(1),
        map(res => { return (res.data['login']) }),
        switchMap(token => {
          let decoded = helper.decodeToken(token);
          this.userData.next(decoded);
          let storageObs = from(this.storage.set(TOKEN_KEY, token));
          return storageObs;
        })
      );
  }

  getUser() {
    return this.userData.getValue();
  }

  logout() {
    this.storage.remove(TOKEN_KEY).then(() => {
      this.router.navigateByUrl('/');
      this.userData.next(null);
    });
  }

}
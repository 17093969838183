import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { CanAuthenticationGuard } from './directives/can-authentication-guard.guard';
import { RequiredRole } from './directives/required-role';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    //canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'organizations',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.organizations,
        },
        { client: 'account', role: 'view-profile' }
      ] as RequiredRole[],
    },
  },
  {
    path: 'organizations/:eid',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.organizations,
        },
        { client: 'account', role: 'view-profile' }
      ] as RequiredRole[],
    },
  },
  {
    path: 'users',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.users,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'users/permission',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
  },
  {
    path: 'users/:userId',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.users,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensor_nodes',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/sensor-nodes/sensor-nodes.module').then(m => m.SensorNodesPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.sensorNodes,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensor_nodes_preset',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/sensor-nodes-preset/sensor-nodes-preset.module').then(m => m.SensorNodesPresetPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.preset,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensor_nodes_preset/:id',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/sensor-nodes-preset/sensor-nodes-preset.module').then(m => m.SensorNodesPresetPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.preset,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensor_nodes/:sensorNodeId',
    loadChildren: () => import('./pages/sensor-nodes/sensor-nodes.module').then(m => m.SensorNodesPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.sensorNodes,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensors',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/sensors/sensors.module').then(m => m.SensorsPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.sensors,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'sensors/:sensorId',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/sensors/sensors.module').then(m => m.SensorsPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.sensors,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'diagnostic_sensors',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/diagnostic-sensors/diagnostic-sensors.module').then(m => m.DiagnosticSensorPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.diagnosticSensors,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'diagnostic_sensors/:diagnosticSensorId',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/diagnostic-sensors/diagnostic-sensors.module').then(m => m.DiagnosticSensorPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.diagnosticSensors,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'diagnostic',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/diagnostic/diagnostic.module').then(m => m.DiagnosticPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.diagnostic,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'search-result',
    //canActivate: [AuthGuard],
    loadChildren: () => import('./search-result/search-result.module').then(m => m.SearchResultPageModule),
  },
  {
    path: 'invalid-data',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/invalid-data/invalid-data.module').then(m => m.InvalidDataPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.invalidData,
        },
      ] as RequiredRole[],
    },
  },
  {
    path: 'keycloak-info',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./pages/keycloak-info/keycloak-info.module').then(m => m.KeycloakInfoPageModule),
    data: {
      roles: [
        {
          client: environment.keycloak.configAdminApp.clientId,
          role: environment.keycloak.configAdminApp.keycloak,
        },
      ] as RequiredRole[],
    },
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-buttons
            routerLink="/home"
            class="ion-justify-content-center cursor-pointer"
          >
            <img src="../assets/images/FINALOPI.png" class="logo-size" />
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <!-- <ion-list-header></ion-list-header> -->
          <ion-item
            [class.isActive]="selectedItem === 'home'"
            slot="header"
            [routerLink]="['/home']"
            button
          >
            <ion-label> {{ 'MENU.HOME' | translate }}</ion-label>
          </ion-item>
          <ion-accordion-group #accordionGroup>
            <ion-accordion *ngFor="let page of appPages" [value]="page.value">
              <ng-container *ngIf="showAccordion(page.children)">
                <ion-item slot="header">
                  <ion-label>{{ page.title | translate }}</ion-label>
                </ion-item>
                <ng-container *ngFor="let pageChildren of page.children">
                  <ion-item
                    *ngIf="pageChildren?.requiredRoles"
                    [class.isActive]="
                      selectedItem === pageChildren.url &&
                      pageChildren.children.length === 0
                    "
                    lines="none"
                    detail="false"
                    slot="content"
                    [routerLink]="[pageChildren.url]"
                    button
                  >
                    <ion-icon
                      slot="start"
                      [ios]="pageChildren.icon + '-outline'"
                      [md]="pageChildren.icon + '-sharp'"
                    ></ion-icon>
                    <ion-label>{{ pageChildren.title | translate }}</ion-label>
                  </ion-item>
                </ng-container>
              </ng-container>
            </ion-accordion>
          </ion-accordion-group>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <app-footer></app-footer>
</ion-app>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EmptySectionComponent } from './empty-section.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmptySectionComponent],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  exports: [EmptySectionComponent]
})
export class EmptySectionModule { }

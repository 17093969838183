import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EmptySectionModule } from '../empty-section/empty-section.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    EmptySectionModule
  ],
  exports: [FooterComponent]
})
export class FooterModule { }

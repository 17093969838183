/**
 * @license
 * Copyright Evja SRL. All Rights Reserved.
 */
export const environment = {
  logLevel: 1, // TRACE = 0 | DEBUG = 1 | INFO = 2 | LOG = 3 | WARN = 4 | ERROR = 5| FATAL = 6 | OFF = 7 (nb to see debug logs in the console enable verbose in your browser dev tools)
  production: false,
  emailEvja: 'opi@evja.eu',
  whatsappEvja: 'https://api.whatsapp.com/send?phone=393401522358&text=',
  graphqlApi: `https://api.evja.org/graphql/`,
  graphqlAdmin: `https://api.evja.org/gqladmin/`,
  subscriptionSecureKey: '6b79a353-fac3-4bb3-a1fb-5b1a81324628',
  keycloak: {
    enabled: true,
    refreshTokenIntervalMS: 3600000,
    initTimeout: 5000,
    configAdminApp: {
      realm: 'OPI-Dev',
      url: 'https://authz.evja.org/auth',
      clientId: 'angular-admin',
      userRole: 'user-role',
      adminRole: 'admin-role',
      guestRole: 'guest-role',
      operationRole: 'operation-role',
      monitoringRole: 'monitoring-role',
      fullRole: 'full-role',
      organizations: 'organizationsRoute',
      users: 'usersRoute',
      sensorNodes: 'sensorNodesRoute',
      sensors: 'sensorsRoute',
      diagnosticSensors: 'diagnosticSensorsRoute',
      preset: 'presetRoute',
      diagnostic: 'diagnosticRoute',
      invalidData: 'invalidDataRoute',
      keycloak: 'keycloakRoute',
      adminAppUser: 'adminAppUser',
    },
    keycloakAPIInfo:{
      client_id:'fastapi-admin',
      grant_type:'client_credentials',
      client_secret:'4dee9b9a-b1b1-41b6-bc78-22a1e0c59cc7',
      id_client:'567de36e-1f0f-47a7-a967-33d07b3becfd'
    }
  },
  protocol: 'https',
  /* Firebase Config Dev */
  firebaseConfig: {
    enabled: true,
    apiKey: 'AIzaSyAznTknpuIyB8FS95Dq5i6C34oKsLj4BOA',
    authDomain: 'evja-dev.firebaseapp.com',
    projectId: 'evja-dev',
    storageBucket: 'evja-dev.appspot.com',
    messagingSenderId: '345438592346',
    appId: '1:345438592346:web:53f5ec944800acaf3ba763',
    measurementId: 'G-92SHYY10GE',
  },
  displayProgressLog: true,
  delayBeforeNavigationToErrorPage: 3000,
  formValidation: {
    emailPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    defaultMinlength: 3,
    passwordMinLength: 8
  }
};

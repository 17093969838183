<ion-card class="empty-section">
  <ion-card-content>
    <ion-icon class="icon-search" name="search-outline"></ion-icon>
    <ion-text class="ion-margin-bottom title">{{
      'SEARCH.EMPTY' | translate
    }}</ion-text>
    <ion-text
      ><h3>{{ 'SEARCH.SUGGEST' | translate }}</h3></ion-text
    >
  </ion-card-content>
</ion-card>

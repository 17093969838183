import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
  constructor(
    private logger: NGXLogger,
    private keycloakService: KeycloakService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.keycloakService.shouldUpdateToken(request)) {
      this.logger.debug('[Token Refresh Interceptor]', 'Refreshing token');
      this.keycloakService.updateToken(300).then((t) => {
        const token = this.keycloakService.getToken();
        this.logger.debug(
          '[Token Refresh Interceptor]',
          'Token updated',
          token,
          t,
        );
      });
    }
    return next.handle(request);
  }
}

import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { InMemoryCache, ApolloLink, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { KeycloakService } from 'keycloak-angular';
import { onError } from "@apollo/client/link/error";
// import { LoadingService } from './services/loading/loading.service';
import { BehaviorSubject } from 'rxjs';
import { RetryLink } from "apollo-link-retry";
import { debounceTime, filter } from 'rxjs/operators';
import { ErrorPolicy } from 'apollo-client';

let uri = environment.graphqlApi; // <-- add the URL of the GraphQL server here
const ws_uri = (environment.protocol === 'https' ? 'wss' : 'ws') + uri.substr(uri.indexOf(":"));
const secure_key = environment.subscriptionSecureKey;
const networkStatus = new BehaviorSubject(false);
let message = null;
// Use this url for test in local
// const ws_uri = "ws://localhost:8090/graphql/subscription";

export function createApollo(uri:string,httpLink?: HttpLink) {
  const keycloakService = new KeycloakService();
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));
  const auth = setContext(async (operation, context) => {
    const token = localStorage.getItem('access_token');
    if (token === null) {
      return {};
    } else {
      return {
        headers: new HttpHeaders({
          "access_token": token,
        })
      };
    }
  });

  const retryLink = new RetryLink();

  const http = ApolloLink.from([ basic, auth, httpLink.create({ uri })]);

  const ws = new WebSocketLink({
    uri: uri,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        const token = localStorage.getItem('access_token');
        return { "access_token": secure_key };
      }
    }
  });
  const link = split(
    ({ query }) => {
      let definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    http,
  );
  const cache = new InMemoryCache();
  const defaultOptions  = {
    watchQuery: {
      errorPolicy: 'all' as ErrorPolicy
    }
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      const oldHeaders = operation.getContext()["headers"];
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: localStorage.getItem('access_token'),
        },
      });
      // retry the request, returning the new observable
      return forward(operation);

      //Use this code in future, in case of different graphQLErrors
      //with the switch-case check the error, based on the error menages it

      // for (let err of graphQLErrors) {
      //   switch (err.extensions.code) {
      //     case 'UNAUTHENTICATED':
      //       // error code is set to UNAUTHENTICATED
      //       // when AuthenticationError thrown in resolver

      //       // modify the operation context with a new token
      //       const oldHeaders = operation.getContext().headers;
      //       operation.setContext({
      //         headers: {
      //           ...oldHeaders,
      //           authorization: keycloakService.getToken(),
      //         },
      //       });
      //       // retry the request, returning the new observable
      //       return forward(operation);
      //   }
      // }
    }
    if (networkError) {
      console.log(`[Network error]:`, networkError );
      networkStatus.next(true);
      message = networkError;
      //return forward(operation);

      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
  );

  const httpLinkWithErrorHandling = ApolloLink.from([
    errorLink,
    link,
  ]);

  return {
    link: httpLinkWithErrorHandling,
    cache,
    defaultOptions,
    retryLink
  };

}

@NgModule({
  imports:[ApolloModule],
  providers: [
    {
      provide: KeycloakService
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink:HttpLink): NamedOptions {
        return {
          default: createApollo(environment.graphqlApi, httpLink),
          gqlAdmin: createApollo(environment.graphqlAdmin, httpLink),
        }
      },
      deps: [HttpLink],
    },
  ],
})

export class GraphQLModule {
  constructor(
    // loadingService: LoadingService
  ) {
    networkStatus
    .pipe(
      filter(v => v === true),
      debounceTime(1000)
    )
    // .subscribe(() => loadingService.addFatalError({type: 'NETWORK', message}))
  }
 }

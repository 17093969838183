import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, ToastController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { first, from, interval, timeout } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './components/footer/footer.module';
import { GraphQLModule } from './graphql.module';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import { TokenRefreshInterceptor } from './services/token-refresh.interceptor';
import { KeycloakInfoService } from './services/keyclok-info.service';
const keycloakService = new KeycloakService();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    GraphQLModule,
    //GraphqlGqlModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    FooterModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      colorScheme: [
        'purple',
        'purple',
        'green',
        'black',
        'yellow',
        'red',
        'red',
      ],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'it',
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: KeycloakService, useValue: keycloakService },
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true
    }

  ],
  exports: []
})


export class AppModule implements DoBootstrap {
  constructor(private logger: NGXLogger,
              private route: Router,
              private toastController: ToastController,) { }
  ngDoBootstrap(appRef: ApplicationRef) {
    if (!environment.keycloak.enabled) {
      appRef.bootstrap(AppComponent);
    } else {
      let keycloakInit = keycloakService.init({
        config: environment.keycloak.configAdminApp,
        initOptions: {
          onLoad: 'login-required', // onLoad: 'login-required',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          checkLoginIframe: false
        },
        loadUserProfileAtStartUp: true,
        // enableBearerInterceptor: true,
        bearerExcludedUrls: [
          '/assets/test.json'
        ]
      }).then(() => {
        this.logger.debug('[ngDoBootstrap] bootstrap app');
        // interval to refresh keycloak token
        // retrieving token
        if (!keycloakService.isUserInRole(environment.keycloak.configAdminApp.adminAppUser)) {
          this.logger.debug('[ngDoBootstrap] adminAppUser not found, logged out!');
          keycloakService.logout();
        }

        keycloakService.getKeycloakInstance().onTokenExpired = ()=> {
          keycloakService.logout();
          this.logger.debug('Token expired');

        }
        if (keycloakService.isTokenExpired()) {
          console.log('TOKEN EXPIRED');
        }
        else {
          from(keycloakService.getToken())
            .pipe(first())
            .subscribe((token) => {
              // saving the token in the storage for apollo usage
              localStorage.setItem('access_token', token);
            });
        }
        interval(environment.keycloak.refreshTokenIntervalMS)
          .subscribe(() =>
            keycloakService.updateToken()
              // the token will refresh only if it's expired
              .then((value) => {
                if (value) {
                  // Reset if state is back to normal
                  // this.loadingService.removeFatalErrors();
                  console.log("Keycloak token successfully refreshed");
                  keycloakService.getToken().then(token => localStorage.setItem("access_token", token));
                } else {
                  console.log("Error refreshing Keycloak token: token is still valid");
                }
              })
              .catch((e) => console.log("Error refreshing Keycloak token: ", e)));
        appRef.bootstrap(AppComponent);
      }).catch(error => {
        const message = '[ngDoBootstrap] init Keycloak failed ' + error
        // this.loadingService.addFatalError({
        //   type: 'AUTHENTICATION',
        //   message
        // });
        console.log(message, error);
      });
      let keycloakInit$ = from(keycloakInit);
      keycloakInit$.pipe(timeout(environment.keycloak.initTimeout)).subscribe(v => { }, e => {
        const err = "Unable to establish a connection with the Identity and Access Manager";
        // this.loadingService.addWarning({
        //   component: COMPONENTS_LIST.APP,
        //   err
        // })
        console.log(err);
        appRef.bootstrap(AppComponent);
        // this.router.navigateByUrl('/error');
      });
    }
    keycloakService.keycloakEvents$.subscribe((event) => {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        keycloakService.logout().then(
          () => {
            this.route.navigateByUrl('/');
          }
        );

      }
    });
  }

}



import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss'],
})

export class EmptySectionComponent implements OnInit {

  @Input() tableData;

  ngOnInit(): void {

  }
}
